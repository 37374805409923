<template>
  <div class="invited">
    <div class="info-box">
        <div class="">
            <van-cell-group inset>
                <van-field v-model="oldpassword" type="password" placeholder="请输入旧密码" />
                <van-field v-model="newpassword" type="password" placeholder="请输入新密码" />
                <van-field v-model="confirmpassword" type="password" placeholder="请确认新密码" />
            </van-cell-group>
        </div>
    </div>
    <div class="btn-box">
        <div class="btn-inner">
            <div class="btn-item" @click="sysUserResetPassword()">保 存</div>
        </div>
    </div>
  </div>
</template>

<script>
import { Toast,  Cell, CellGroup, Field} from "vant";
export default {
  name: "index",
  data() {
    return {
      username:this.$route.query.urlVal,
      oldpassword:"",
      newpassword:"",
      confirmpassword:'' 
    };
  },
  methods: {
    // 修改密码
    async sysUserResetPassword() {
      if(!this.oldpassword){
        return Toast('请输入旧密码')
      }else if(!this.newpassword){
        return Toast('请输入新密码')
      }else if(this.newpassword !== this.confirmpassword){
        return Toast('新密码确认不正确')
      }
      let params = {
        username:this.username,
        oldpassword:this.oldpassword,
        newpassword:this.newpassword,
        confirmpassword:this.confirmpassword
      };
      await this.$post(`/h5/weChat/sysUserResetPassword`, params).then((res) => {
        if (res.code == "0") {
          Toast(res.message)
          this.$router.push({path:'/'})
        }else{
          return Toast(res.message)
        }
      });
    },
  },
  created() {
  },
  mounted() {},
  components: {
    [Toast.name]: Toast,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
  },
};
</script>

<style lang="scss" scoped>

.invited {
  background: #f4f4f4;
  min-height: 100vh;
  overflow: hidden;
}
.info-box{
    margin: 15px 0;
    .van-cell{
        padding: 15px;
        font-size: 16px;
    }
}
.c-4D4D4D{
    color: #4D4D4D;
}
.w40{
    flex: 0 0 40%;
}
.w55{
    flex: 0 0 55%;
}

</style>